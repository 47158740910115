<template>
  <RouterView />
  <div class="statuses pos-r z1 pt-20 pb-30">
    <div class="flex ai-c jc-sb ggap-20">
      <h2 class="title">Все статусы</h2>
      <div class="flex ai-c ggap-20">
        <a v-if="!isReset" href="#" class="alink t-red" @click.prevent="resetAction">Сбросить изменения</a>
        <RouterLink to="/settings/statuses/add" class="btn primary">
          <BaseIcon class="ic-16 white" icon="plus-circle" />
          Добавить статус
        </RouterLink>
      </div>
    </div>

    <div class="box mt-20">
      <table class="base-table w-100">
        <thead>
          <th>ID</th>
          <th>Название</th>
          <th>Тип</th>
          <th>Цвет текста</th>
          <th>Цвет фона</th>
          <th>Просмотр</th>
        </thead>

        <tbody>
          <Row v-for="row of rows" :key="row.id" :row="row" @deleteAction="deleteAction" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import statuses from '@/api/modules/statuses'
import statusesDefault from '@/assets/json/statuses-default'
import { useStore } from 'vuex'
import { defineEmits, computed } from 'vue'
import { BaseIcon } from '@/components'
import { $busEmit } from '@/plugins'
import Row from './components/Row.vue'

// Emits
const emits = defineEmits(['loadAction'])

// Data
const store = useStore()

// Computed
const rows = computed(() => {
  return store.getters['helpers/statuses']
})
const isReset = computed(() => {
  const rowsStatic = rows.value?.length ? rows.value.filter((item) => !!item.is_static) : []
  const res =
    statusesDefault.length === rowsStatic.length &&
    statusesDefault.every(function (element) {
      const item = rowsStatic.find((el) => el.id === element.id)
      return (
        element.name === item.name &&
        element.text_color === item.text_color &&
        element.background_color === item.background_color
      )
    })
  return res
})

// Created
if (!rows.value?.length) {
  emits('loadAction', true)
  store.dispatch('helpers/GET_STATUSES').finally(() => emits('loadAction', false))
}

// Methods
function deleteAction(id) {
  emits('loadAction', true)
  statuses
    .delete(id)
    .then(() => {
      store.dispatch('helpers/GET_STATUSES').finally(() => {
        emits('loadAction', false)
        $busEmit('setToast', {
          type: 'green',
          icon: 'check-circle',
          message: 'Статус успешно удален, востановить его нельзя.'
        })
      })
    })
    .catch((e) => {
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: e.response.status + ' ' + e.response.statusText,
        message: 'Что-то пошло не так, обратитесь к администратору, или попробуйте еще раз'
      })
    })
    .finally(() => {
      emits('loadAction', false)
    })
}
async function resetAction() {
  emits('loadAction', true)
  Promise.all(
    statusesDefault.reduce(function (p, next) {
      const curr = Promise.resolve(typeof next === 'function' ? next() : next)
      return p.concat.apply(p, [
        curr.then(async function (status) {
          const payload = { ...status }
          delete payload.id
          await statuses.update(status.id, payload)
        })
      ])
    }, [])
  )
    .catch((e) => {
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: e.response.status + ' ' + e.response.statusText,
        message: 'Что-то пошло не так, один или несколько статусов не удалось сбросить.'
      })
    })
    .finally(async () => {
      await store.dispatch('helpers/GET_STATUSES')
      emits('loadAction', false)
      $busEmit('setToast', {
        type: 'blue',
        icon: 'check-circle',
        message: 'Все статусы успешно сброшены до состояния по умолчанию.'
      })
    })
}
</script>

<style lang="scss" scoped>
.statuses {
}
</style>
