export default [
  {
    id: 1,
    uid: 'new',
    name: 'Новый',
    text_color: '#FFF',
    background_color: '#096dd9',
    is_static: true
  },
  {
    id: 2,
    uid: 'in-work',
    name: 'В работе',
    text_color: '#096dd7',
    background_color: '#e7f2fe',
    is_static: true
  },
  {
    id: 3,
    uid: 'canceled',
    name: 'Отклонен',
    text_color: '#FFF',
    background_color: '#E31E25',
    is_static: true
  },
  {
    id: 4,
    uid: 'completed',
    name: 'Завершен',
    text_color: '#FFF',
    background_color: '#4caf50',
    is_static: true
  },
  {
    id: 5,
    uid: 'in-watch',
    name: 'На рассмотрении',
    text_color: '#1c8b1f',
    background_color: '#d5f1d6',
    is_static: true
  },
  {
    id: 6,
    uid: 'in-approval',
    name: 'На согласовании',
    text_color: '#B11176',
    background_color: '#FFDCF3',
    is_static: true
  },
  {
    id: 7,
    uid: 'draft',
    name: 'Черновик',
    text_color: '#000',
    background_color: '#fff1c7',
    is_static: true
  },
  {
    id: 8,
    uid: 'deleted',
    name: 'Удален',
    text_color: '#f44336',
    background_color: '#ffe6e5',
    is_static: true
  }
]
